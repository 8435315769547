import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StudioTop from '../../components/studio/StudioTop';


export default function Studio(){
    return(
        <>
            <p>This is the studio</p>

            <StudioTop />
        
        </>
    )
}