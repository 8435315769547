import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import tmdbApi from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";

import Button, { OutlineButton } from "../../components/button/Button";

import "./detail.scss";
import CastList from "./CastList";
import VideoList from "./VideoList";
import MovieList from "../../components/movie-list/MovieList";
import { formatDistance } from "date-fns";
import { useHistory, useLocation } from "react-router";
import { Grid, makeStyles } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import ShowMoreText from "react-show-more-text";
import {Button as But} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { usePaystackPayment } from "react-paystack";
import Alert from "@mui/material/Alert";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
// import LikersModal from "../../../components/LikersModal";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import ImageComponentDiv from "../../components/others/ImageComponentDiv";

const useStyles = makeStyles((theme) => ({
  pc: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Detail = () => {
  const classes = useStyles();
  const { category, id } = useParams();

  const [item, setItem] = useState(null);

  useEffect(() => {
    const getDetail = async () => {
      const response = await tmdbApi.detail(category, id, { params: {} });
      setItem(response);
      window.scrollTo(0, 0);
    };
    getDetail();
  }, [category, id]);

  return (
    <>
      {item && (
        <>
          <ImageComponentDiv
            class="banner"
            image={apiConfig.originalImage(
              item.backdrop_path || item.poster_path
            )}
          />
          {/* <div className="banner" style={{backgroundImage: `url(${apiConfig.originalImage(item.backdrop_path || item.poster_path)})`}}></div> */}
          <div style={{ paddingTop: 60 }}></div>
          <div className="mb-3 movie-content container">
            <div className="movie-content__poster">
              <ImageComponentDiv
                class="movie-content__poster__img"
                image={apiConfig.originalImage(
                  item.poster_path || item.backdrop_path
                )}
              />
              {/* <div className="movie-content__poster__img" style={{backgroundImage: `url(${apiConfig.originalImage(item.poster_path || item.backdrop_path)})`}}></div> */}
              <br />
              <br />
              <div className="clearfix"></div>
              <div className="star d-block w-100 text-left mt-2">
                <img
                  src="/assets/images/star.png"
                  alt="star"
                  className="w15 float-left"
                  style={{ height: 20, width: 20 }}
                />
                <img
                  src="/assets/images/star.png"
                  alt="star"
                  className="w15 float-left"
                  style={{ height: 20, width: 20 }}
                />
                <img
                  src="/assets/images/star.png"
                  alt="star"
                  className="w15 float-left"
                  style={{ height: 20, width: 20 }}
                />
                <img
                  src="/assets/images/star.png"
                  alt="star"
                  className="w15 float-left"
                  style={{ height: 20, width: 20 }}
                />
                <img
                  src="/assets/images/star-disable.png"
                  alt="star"
                  className="w15 float-left me-2"
                  style={{ height: 20, width: 20 }}
                />
              </div>
              <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                (108 ratings ) 501 comments
              </p>
              <div className="clearfix"></div>
              <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                <i className="btn-round-sm bg-greylight feather-users text-grey-500 me-1"></i>{" "}
                6 casts
              </h5>

              <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                This movie is not free. Buy and watch now for N 4000
              </h5>
            </div>

            <div className="movie-content__info">
              <Grid item sm={12} className={classes.mobile}>
                <ImageComponentDiv
                  class="movie-content__poster__img"
                  image={apiConfig.originalImage(
                    item.poster_path || item.backdrop_path
                  )}
                />
                <br />
              </Grid>
              <h1 className="title">{item.title || item.name}</h1>
              <div className="genres">
                {item.genres &&
                  item.genres.slice(0, 5).map((genre, i) => (
                    <span key={i} className="genres__item">
                      {genre.name}
                    </span>
                  ))}
              </div>
              <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white">
                {/* {movie.title} */}
                Status : Released 10 mins ago
                {/* {formatDistance(new Date(movie.created_at), new Date(), {
                    addSuffix: true,
                  })} */}
              </h4>
              <h4
                className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1"
                style={{ marginTop: -20 }}
              >
                {/* {movie.title} */}
                Type : Movie
                {/* {movie.type == "episode" ? (
                    <>
                      <a
                        href={`/channel/${props.channelSlug}/series/${props.seriesSlug}?channel=${props.channelSlug}&w=${props.seriesId}&a=${props.adminId}`}
                      >
                        <span
                          className="genres__item"
                          style={{
                            padding: "0.5rem 1.5rem",

                            color: "#fff",
                            display: "inline-block",
                            marginTop: 5,
                          }}
                        >
                          View Series
                        </span>
                      </a>
                    </>
                  ) : (
                    ""
                  )} */}
              </h4>

              <Grid item sm={12} className={classes.mobile}>
                <div className="star d-block w-100 text-left mt-2">
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src="/assets/images/star-disable.png"
                    alt="star"
                    className="w15 float-left me-2"
                    style={{ height: 20, width: 20 }}
                  />
                </div>
                <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                  (108 ratings ) 501 comments
                </p>
                <div className="clearfix"></div>
                <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                  <i className="btn-round-sm bg-greylight feather-users text-grey-500 me-1"></i>{" "}
                  378 casts
                </h5>
                <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                  <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                  This movie is not free. Buy and watch now for N 4000
                </h5>
                <br /> <br />
              </Grid>
              {/* <p className="overview">{item.overview}</p> */}
              <ShowMoreText
                style={{ color: "#fff", fontWeight: 700 }}
                lines={3}
                more="Read more"
                less="Show less"
                className="overview"
                //   anchorClass="my-anchor-css-class"
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                {item.overview}
              </ShowMoreText>

              {/* <div className="hero-slide__item__content__info"> */}
              <div className="btns">
                <Button onClick={() => alert("video comes here")}>Watch now</Button>
                <OutlineButton onClick={() => alert("trailer comes here")}>
                  Watch trailer
                </OutlineButton>
              </div>

              {/* </div> */}
                <OutlineButton variant="outlined" style={{color:'#fff'}}
                onClick={() => alert("comments pops up here")}>
                    Add a Review / Comment 
                </OutlineButton>
              {/* <a
                 onClick={() => alert("comments pops up here")}
                className="d-block p-2 lh-32 text-center bg-greylight fw-600 font-xssss text-grey-900 rounded-3"
              >
                Add a Review / Comment
              </a> */}

              <div className="cast">
                <div className="section__header">
                  <h2>Casts</h2>
                </div>
                <CastList id={item.id} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section mb-3">
              {/* show other movies of auther here */}
              <VideoList id={item.id} />
            </div>
            <div className="section mb-3">
              <div className="section__header mb-2">
                <h1>Similar Movies</h1>
              </div>
              {/* get and show movies that belog to this gener or tag */}
              <MovieList category={category} type="similar" id={item.id} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Detail;
