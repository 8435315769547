import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router";
import ReactPlayer from "react-player";
import tmdbApi from "../../api/tmdbApi";

const VideoList = (props) => {
  const { category } = useParams();

  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const getVideos = async () => {
      const res = await tmdbApi.getVideos(category, props.id);
      setVideos(res.results.slice(0, 5));
    };
    getVideos();
  }, [category, props.id]);

  return (
    <>
    <h1>More Movies In Channel</h1><br/>
      {videos.map((item, i) => (
        <Video key={i} item={item} />
      ))}
    </>
  );
};

const Video = (props) => {
  const item = props.item;

  const iframeRef = useRef(null);

  useEffect(() => {
    // const height = (iframeRef.current.offsetWidth * 9) / 16 + "px";
    // iframeRef.current.setAttribute("height", height);
  }, []);

  return (
    <div className="video">
      <div className="video__title">
        <h2>{item.name}</h2>
        {/* <p>video name is here</p> hegguwguui*/}
      </div>
      {/* <iframe
        src={`https://www.youtube.com/embed/${item.key}`}
        ref={iframeRef}
        width="100%"
        title="video"
      ></iframe> */}
      <ReactPlayer
        url={`https://www.youtube.com/embed/${item.key}`}
        controls={true}
        config={{ file: { attributes: { controlsList: "nodownload" } } }}
        playsinline={true}
        width="100%"
        // height='600px'
      />
    </div>
  );
};

export default VideoList;
