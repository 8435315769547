import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


export default function StudioTop(){
    return(
        <>
            <p>This is the studio top</p>
        
        </>
    )
}